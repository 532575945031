import React, { FC } from 'react'
import { CreateFormModalButton } from 'components/create-form-modal'
import { trackWinDashboardClick } from './tracker'
import { User } from 'store/modules/users'
import { WinDashboardProps } from '.'

export const WinDashboardHeader: FC<
  Pick<WinDashboardProps, 'self'> & { user: User }
> = ({ self, user }) => {
  const href = React.useMemo(() => {
    let path = `/wins/new?page_source=win_dashboard_header`
    if (!self) path += `&win[winner_ids][]=${user.id}`

    return path
  }, [self, user])

  const onClick = () =>
    trackWinDashboardClick({
      href: href,
      title: 'Clicked view all wins',
    })

  return (
    <div className="flex align-items-center">
      <h3 className="mr-4">Recent Wins</h3>
      <a
        href={`/users/${user.slug}/wins/received`}
        className="ml-auto mr-2 sm:mr-6"
        onClick={onClick}
      >
        View all
      </a>
      <CreateFormModalButton
        initialUserIds={[user.id]}
        label="Add Win"
        source="win_dashboard_header"
        tabs={['win']}
        user={user}
      />
    </div>
  )
}
