import { trackEvent } from '../../services/event-tracker'

type NavLink = {
  title: string
  href?: string
}

export const trackWinDashboardClick = (link: NavLink) => {
  trackEvent('$track_win_dashboard_view_all_click', { link })
}
