import { ChangeIndicator } from 'components/change-indicator'
import { useRailsContext } from 'components/rails-context'
import React, { FC, useMemo } from 'react'
import { Win } from '../../types/entities'
import { Block } from './block'
import styles from './styles.module.scss'

const splitWins = (
  userId: string | number | undefined,
  wins: Win[],
  given: boolean
) =>
  wins.reduce(
    (split, win) => {
      const toCompare = given ? win.winnerId : win.reporterId
      if (toCompare === userId) split.mine.push(win)
      else split.theirs.push(win)
      return split
    },
    { mine: [] as Win[], theirs: [] as Win[] }
  )

type WinDashboardStatsProps = {
  wins: Win[]
  given?: boolean
  previous: {
    mine: number
    theirs: number
  }
}

export const WinDashboardStats: FC<WinDashboardStatsProps> = ({
  wins,
  given = false,
  previous,
}) => {
  const { currentUser } = useRailsContext()
  const { mine, theirs } = useMemo(
    () => splitWins(currentUser?.id, wins, given),
    [currentUser?.id, wins, given]
  )

  if (!currentUser?.id) return null
  const direction = given ? 'To' : 'From'
  const title = given ? 'By recipient' : 'By reporter'
  return (
    <>
      <p>{title}</p>
      <div className="d-flex">
        <div className="mr-6">
          <span className={styles.count}>{mine.length}</span>
          <ChangeIndicator from={previous.mine} to={mine.length} />
          <p className="lighten">
            <Block
              fill="var(--progression-green)"
              className="mr-1 align-baseline"
            />
            {direction} you
          </p>
        </div>
        <div>
          <span className={styles.count}>{theirs.length}</span>
          <ChangeIndicator from={previous.theirs} to={theirs.length} />
          <p className="lighten">
            <Block
              fill="var(--progression-gold)"
              className="mr-1 align-baseline"
            />
            {direction} others
          </p>
        </div>
      </div>
    </>
  )
}
