import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useStore } from 'store/context'
import { WorkFeed } from 'components/home-page/work-feed'
import { removeUrlParams } from '../../utils/url-helpers'

export const HomePage = observer(() => {
  const { currentUser } = useStore()

  const startNewStory =
    Boolean(
      new URLSearchParams(window.location.search).get('start_new_story')
    ) || undefined

  const initialLayout = startNewStory ? 'grid' : 'list'
  const initialStoryMode = startNewStory ? 'creating' : 'none'

  React.useEffect(() => {
    removeUrlParams('start_new_story')
  }, [])

  if (!currentUser) return null

  return (
    <section className="h-full">
      <div className="flex flex-row h-full w-full">
        <div className="flex-1 max-w-full">
          <WorkFeed
            currentUser={currentUser}
            initialStoryMode={initialStoryMode}
            initialLayout={initialLayout}
          />
        </div>
      </div>
    </section>
  )
})
