import cn from 'classnames'
import { useRailsContext } from 'components/rails-context'
import { format, formatISO, parseISO, subDays } from 'date-fns'
import React, { CSSProperties, FC, useEffect, useMemo } from 'react'
import { Win } from '../../types/entities'
import { Block } from './block'
import styles from './styles.module.scss'

const generateDays = () =>
  [...Array(30)]
    .map((_, n) =>
      formatISO(subDays(new Date(), n), { representation: 'date' })
    )
    .reverse()

const groupWins = (wins: Win[]) =>
  wins.reduce<Record<string, Win[]>>((hsh, win) => {
    if (!hsh[win.tookPlaceOn]) hsh[win.tookPlaceOn] = []
    hsh[win.tookPlaceOn].push(win)
    return hsh
  }, {})

const colorFor = (win: Win, userId: number, given: boolean) => {
  const toCompare = given ? win.winnerId : win.reporterId
  return toCompare === userId
    ? 'var(--progression-green)'
    : 'var(--progression-gold)'
}

const formatDay = (day: string) => format(parseISO(day), 'do LLLL')

type ChartBlockProps = {
  win: Win
  col: number
  row: number
  color: string
  total: number
  day: string
}
const ChartBlock: FC<ChartBlockProps> = ({
  win,
  col,
  row,
  total,
  color,
  day,
}) => {
  const dateStr = formatDay(day)
  const countStr = `${total} ${total == 1 ? 'win' : 'wins'}`
  const style = {
    '--row': row,
    '--col': col,
    backgroundColor: color,
    opacity: total > 5 && row === 1 ? 0.5 : 1,
  } as CSSProperties
  return (
    <a
      data-toggle="tooltip"
      data-placement="bottom"
      title={`${dateStr}\n${countStr}`}
      href={`/wins/${win.id}`}
      data-remote
      style={style}
      className={cn(styles.block, styles.chartBlock)}
    />
  )
}

type ChartRowProps = {
  day: string
  wins: Win[]
  userId: number
  index: number
  given: boolean
}
const ChartRow: FC<ChartRowProps> = ({
  userId,
  wins = [],
  index,
  day,
  given,
}) => {
  if (wins.length === 0) {
    const dateStr = formatDay(day)
    return (
      <Block
        size="auto"
        className={styles.chartBlock}
        data-toggle="tooltip"
        data-placement="bottom"
        title={`${dateStr}\n0 wins`}
        style={{ '--row': 5, '--col': index + 1 } as CSSProperties}
      />
    )
  }
  return (
    <>
      {wins.slice(0, 5).map((win, rowIndex) => (
        <ChartBlock
          win={win}
          row={5 - rowIndex}
          col={index + 1}
          key={win.id}
          total={wins.length}
          color={colorFor(win, userId, given)}
          day={day}
        />
      ))}
    </>
  )
}

export const WinDashboardChart: FC<{ wins: Win[]; given: boolean }> = ({
  wins,
  given,
}) => {
  const groupedWins = useMemo(() => groupWins(wins), [wins])
  const days = useMemo(generateDays, [])
  const { currentUser } = useRailsContext()

  useEffect(() => {
    window.initTooltips()
  }, [wins])

  if (!currentUser?.id) return null

  return (
    <div className={cn(styles.chart, 'mt-6')}>
      {days.map((day, index) => (
        <ChartRow
          given={given}
          index={index}
          userId={parseInt(currentUser.id.toString(), 10)}
          key={day}
          wins={groupedWins[day]}
          day={day}
        />
      ))}
    </div>
  )
}
