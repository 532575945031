import React, { FC } from 'react'
import { CreateFormModalButton } from 'components/create-form-modal'
import { User } from 'store/modules/users'
import { WinDashboardProps } from '.'

export const EmptyState: FC<
  Pick<WinDashboardProps, 'given' | 'self'> & {
    user: User
  }
> = ({ given, self, user }) => {
  return (
    <div className="text-center mx-auto">
      <p className="text-lg bold mb-2">
        {self ? "You haven't" : `${user.fname} hasn't`}{' '}
        {given ? 'given' : 'received'} any wins recently
      </p>
      <p className="max-w-lg mx-auto">
        Wins are achievements at work.
        <br />
        Add them for yourself or someone else, link them to skills for your next
        check-in and download them any time!{' '}
        <a
          className="underline"
          href="https://help.progression.co/6521566-activity-wins"
          target="_blank"
          rel="noreferrer"
        >
          More
        </a>
      </p>
      <CreateFormModalButton
        initialUserIds={[user.id]}
        source="win_dashboard_empty"
        tabs={['win']}
        user={user}
      />
    </div>
  )
}
