import React, { FC } from 'react'

export const ChangeIndicator: FC<{ from: number; to: number }> = ({
  from,
  to,
}) => {
  const raw = from == 0 ? (to == 0 ? 0 : 100) : ((to - from) / from) * 100
  const value = Math.round(Math.abs(raw))
  const sym = raw < 0 ? '-' : '+'
  const color = raw < 0 ? 'var(--red)' : 'var(--green)'

  return (
    <span style={{ color }}>
      {sym}
      {value}%
    </span>
  )
}
