import cn from 'classnames'
import React, { FC } from 'react'

type SkillCount = { id: number; name: string; count: number; slug: string }
type WinDashboardSkillsProps = {
  skills: SkillCount[]
  given: boolean
  orgUserId?: string
  canEditUser: boolean
}

const tagClass = (count: number) => cn('tag', { paper: count === 0 })

export const WinDashboardSkills: FC<WinDashboardSkillsProps> = ({
  skills,
  given,
  orgUserId,
  canEditUser,
}) => {
  if (!skills.length && canEditUser)
    return (
      <>
        <p className="position-absolute top-0">Skills with wins</p>
        {orgUserId && (
          <a
            href={`/org_users/${orgUserId}/edit`}
            data-remote
            className="d-block text-center mt-4"
          >
            Add a position to view
          </a>
        )}
      </>
    )

  skills.sort((a, b) => b.count - a.count)

  return (
    <>
      <p>By skill</p>
      <div className="tags mb-0 h-100 align-content-start">
        {skills.map(({ id, count, name, slug }) => (
          <a
            className={tagClass(count)}
            href={`/users/skills/${slug}`}
            key={`${id}-${given}`}
          >
            {name}
            <span className="ml-2 opacity-80">{count}</span>
          </a>
        ))}
      </div>
    </>
  )
}
