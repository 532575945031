import React, { ComponentPropsWithoutRef, CSSProperties, FC } from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'

export const Block: FC<
  ComponentPropsWithoutRef<'span'> & { fill?: string; size?: number | string }
> = ({ fill = '#eee', className, style, size = '0.6em', ...props }) => (
  <span
    className={cn(className, styles.block)}
    style={
      {
        ...style,
        backgroundColor: fill,
        width: size,
        height: size,
      } as CSSProperties
    }
    {...props}
  />
)
