import React, { FC, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { EmptyState } from './empty'
import { Toggle, ToggleItem } from 'components/atoms/toggle'
import { useStore } from 'store/context'
import { Win } from '../../types/entities'
import { WinDashboardChart } from './chart'
import { WinDashboardHeader } from './header'
import { WinDashboardSkills } from './skills'
import { WinDashboardStats } from './stats'

const toggleClasses = (active: boolean) =>
  cn('mx-2', {
    'opacity-50': !active,
    'bg-theme-90 px-4 text-theme-30': active,
  })

export type WinDashboardProps = {
  totals: {
    given: number
    received: number
  }
  wins: Win[]
  skills: Array<{ id: number; name: string; count: number; slug: string }>
  previous: {
    mine: number
    theirs: number
  }
  given: boolean
  userId: string
  self: boolean
  orgUserId?: string
  canEditUser: boolean
}

export const WinDashboard: FC<WinDashboardProps> = (initialProps) => {
  const { users } = useStore()

  const [props, setProps] = useState(initialProps)
  const [given, setGiven] = useState(false)
  const [loading, setLoading] = useState(false)

  const user = users.byId(props.userId)

  const updateProps = useCallback(
    async (given = false) => {
      setLoading(true)
      const res = await fetch(
        `/api/v1/internal/wins_dashboard?given=${given}&user_id=${props.userId}`,
        {
          credentials: 'include',
        }
      )
      if (!res.ok) {
        setLoading(false)
        return
      }

      const newProps: WinDashboardProps = await res.json()
      setProps(newProps)
      setLoading(false)
    },
    [props.userId]
  )

  useEffect(() => {
    if (props.given !== given) updateProps(given)
  }, [given, props.given, updateProps])

  if (!user) return null

  return (
    <section className="pt-24">
      <WinDashboardHeader {...props} user={user} />
      <Toggle
        value={given ? 'given' : 'received'}
        onChange={(g) => setGiven(g === 'given')}
        className="mb-6 text-center"
      >
        {(val) => (
          <>
            <ToggleItem
              className={toggleClasses(val === 'received')}
              value="received"
            >
              Received
              <span className="ml-2">{props.totals.received}</span>
            </ToggleItem>
            <ToggleItem
              className={toggleClasses(val === 'given')}
              value="given"
            >
              Given
              <span className="ml-2">{props.totals.given}</span>
            </ToggleItem>
          </>
        )}
      </Toggle>
      {props.wins.length > 0 ? (
        <div className="row">
          <div
            className={cn('col-12 col-lg-6 mb-6 sm:mb-0 transition-opacity', {
              'opacity-50': loading,
            })}
          >
            <WinDashboardStats {...props} />
            <WinDashboardChart wins={props.wins} given={given} />
          </div>
          <div
            className={cn(
              'col-12 col-lg-6 d-flex flex-column justify-content-center transition-opacity',
              { 'opacity-50': loading }
            )}
          >
            <WinDashboardSkills
              skills={props.skills}
              given={given}
              orgUserId={props.orgUserId}
              canEditUser={props.canEditUser}
            />
          </div>
        </div>
      ) : (
        <EmptyState {...props} user={user} />
      )}
    </section>
  )
}
